* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  /* --primaryColor: #af9a7d; */
  --primaryGround: #262626;
  --secondaryGround: #464646;
  --primaryColor: #b11c08;
  --mainWhite: #fff;
  --offWhite: ##f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #cfcfcf;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 2px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}
/* globals */
body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--offWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.2;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: var(--mainBlack);
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
}
.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 3rem;
}
.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.2rem 2rem;
  background-color: var(--primaryGround);
  z-index: 1;
  box-shadow: var(--darkShadow);
}
.nav-header {
  display: flex;
  justify-content: space-between;
}
.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 5px;
}
.nav-btn:hover,
.nav-btn:focus,
.nav-btn:active {
  border: 2px solid var(--primaryColor);
}
.nav-icon {
  font-size: 1.5rem;
  color: var(--primaryColor);
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
}
.nav-links a {
  text-transform: uppercase;
  border: 2px solid transparent;
  display: block;
  text-decoration: none;
  padding: 0.75rem 0;
  color: var(--mainWhite);
  transition: var(--mainTransition);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: #d43031;
}
.show-nav {
  height: auto;
}
@media screen and (min-width: 768px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1280px;
    margin: 0px auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 3px 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.foodsHero {
  min-height: calc(100vh - 66px);
  background: url("./images/mrle-background.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.foodsHero {
  background-image: url("./images/mrle-background.jpg");
  min-height: 60vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 1rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.5rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 3rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
.contact-info .section-title {
  text-align: center;
  margin-bottom: 1.5rem;
}
.contact-info .section-title h4 {
  font-size: 1.5rem;
  letter-spacing: var(--mainSpacing);
  text-transform: none;
  margin-bottom: 1rem;
}
.contact-info .section-title div {
  width: 3rem;
  height: 3px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* featured categories */
.featured-categories {
  padding: 1rem 0;
}
.featured-categories-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
@media screen and (min-width: 776px) {
  .featured-categories-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .featured-categories-center {
    width: 90vw;
    max-width: 1280px;
  }
}
/* end of featured categories */
/* category */
.category {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.category:hover {
  box-shadow: var(--darkShadow);
}
.category-info {
  background: var(--secondaryGround);
  color: var(--mainWhite);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 500;
  letter-spacing: var(--mainSpacing);
}
.category-header {
  margin-top: 1rem;
  letter-spacing: var(--mainSpacing);
}
.category-name {
  color: var(--primaryColor);
  text-transform: capitalize;
  font-weight: 600;
  font-size: x-large;
  text-align: center;
}
.category-desc {
  color: #666;
  font-weight: 400;
  font-size: large;
  text-align: center;
  margin: 1rem;
}
.category-desc div {
  width: 5rem;
  height: 5px;
  margin: 15px auto;
  background: var(--primaryColor);
}
/* end of category */
/* food */
.food {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
}
.food:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.food-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.food .icon-chilli {
  display: block;
  float: right;
  padding: 5px;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .food-link {
  transform: translate(-50%, -50%) scale(1);
}
.food-info {
  /* background: var(--secondaryGround);
  color: var(--mainWhite); */
  text-transform: capitalize;
  padding: 0.5rem 5px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: var(--mainSpacing);
}
.food-container {
  position: relative;
  flex-wrap: inherit;
}
.food-container:hover {
  background: #f7e5b5;
}
.food-description {
  font-size: 0.8rem;
  color: #333;
  display: block;
  margin: 5px;
  /* min-height: 100px; */
}
.food-info .price {
  font-weight: 500;
  color: #666;
  margin-left: 10px;
}
.food-info .code {
  /* color: #e68b04; */
  color: var(--primaryColor);
  font-weight: 500;
  border-radius: 16%;
  text-align: center;
  margin-right: 10px;
  /* border: 1px solid #e68b04; */
  border: 1px solid var(--primaryColor);
}
/* end of food  */
/* foodlist */
.foodslist {
  padding: 2rem 0;
}
.foodslist-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .foodslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .foodslist-center {
    width: 90vw;
    max-width: 1280px;
  }
}
/* end of foodlist */
/* contact*/
.contact {
  padding: 0rem 0 0 0;
}
.contact-info {
  width: 80vw;
  display: grid;
  margin: 1rem auto;
  list-style-type: none;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  text-align: center;
  letter-spacing: var(--mainSpacing);
}

.reservation,
.openning,
.address p {
  line-height: 1.8;
  /* margin-bottom: 1rem; */
  text-transform: uppercase;
  content: "\e9d1";
}

.reservation span a {
  display: inline-block;
  font-size: 2rem;
  margin: 0.5rem;
  color: var(--secondaryGround);
}

@media screen and (min-width: 992px) {
  .contact-info {
    width: 90vw;
    max-width: 1280px;
  }
  .contact-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.5rem;
  }
  .reservation,
  .address,
  .openning {
    padding-left: 3rem;
  }
}
/* end of contact*/

.mapy-cz {
  min-height: 700px;
  display: inline-block;
  text-align: center;
  padding-left: 12%;
}
.mapy-cz iframe {
  height: 500px;
  width: 75vw;
  border-style: none;
}
@media screen and (min-width: 992px) {
  .mapy-cz {
    width: 90vw;
    max-width: 1280px;
  }
}
.video-fb {
  text-align: center;
  max-width: 100%;
  margin: 2rem auto;
  display: block;
}
.video-fb-desc {
  color: var(--primaryColor);
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem auto;
  border-style: none;
  letter-spacing: var(--mainSpacing);
}
.video-fb-desc div {
  width: 5rem;
  height: 5px;
  margin: 15px auto;
  background: var(--primaryColor);
  border-style: none;
}
.srdicko-img {
  text-align: center;
  width: 90vw;
  margin: 2.5rem auto;
  display: block;
}
.news-desc {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 1.5rem;
  text-align:left;
  margin: 3rem 4rem;
  border-style: none;
  letter-spacing: var(--mainSpacing);
}
